import { useEffect, useState } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { upperFirst, isEmpty } from 'lodash';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import queryString from 'query-string';

import { fetchLiveDetail, fetchAdditionalInfoLive, fetchRevenueLiveDetail } from '@pages/mainDashboard/helpres/crud';
import EmptyState from '@components/emptyStateMobile';
import ScreenTemplateMobile from '@components/screenTemplateMobile';
import { AdditionalInformationSkeleton, DetailInformationSkeleton } from '@pages/mainDashboard/components/skeleton';
import { getDate, getRangeTime, parseRichText } from '@pages/mainDashboard/helpres/utils';
import { ArrowDownIcon, EditIcon } from '@assets/icons';
import { getCurrency } from '@helpers/util';

import './styles.scss';

const DetailLiveStream = () => {
    const selectedLiveEvent = useParams();
    const currency = getCurrency();
    const navigate = useNavigate();
    const location = useLocation();
    const { title } = queryString.parse(location?.search);
    const [detailInfo, setDetailInfo] = useState({ isLoading: true });
    const [additionalLive, setAdditionalLive] = useState({ isLoading: true });
    const [revenue, setRevenue] = useState({ isLoading: true });
    const [state, setState] = useState({ pageType: 'additional-info' });

    const { schedule } = detailInfo;
    const { pageType, isExpandDetail } = state;
    const { productHighlights, coins, promotions, vouchers } = additionalLive;

    const onChangeStateDetailInfo = (newState) => setDetailInfo({ ...detailInfo, ...newState });
    const onChangeStateAdditionalLive = (newState) => setAdditionalLive({ ...additionalLive, ...newState });
    const onChangeStateRevenueLive = (newState) => setRevenue({ ...revenue, ...newState });
    const onChangeState = (newState) => setState({ ...state, ...newState });

    useEffect(() => {
        /* eslint-disable */

        fetchLiveDetail({ onChangeState: onChangeStateDetailInfo, id: selectedLiveEvent?.id });
        fetchAdditionalInfoLive({ onChangeState: onChangeStateAdditionalLive, id: selectedLiveEvent?.id });
        fetchRevenueLiveDetail({ onChangeState: onChangeStateRevenueLive, id: selectedLiveEvent?.id });
    }, []);

    return (
        <ScreenTemplateMobile headTitle={title} onBack={() => navigate(-1)}>
            <Box className="detail-schedule__container__mobile">
                {/** ------------------------------- INFORMATION -------------------------- */}
                {detailInfo?.isLoading && <DetailInformationSkeleton />}
                {!detailInfo?.isLoading && (
                    <Box className="detail-live__container">
                        <Text className="label-section">Informasi Live:</Text>
                        <Box className="detail-live">
                            <Flex justifyContent="space-between" alignItems="center" w="100%">
                                <Text className="event-title">{upperFirst(schedule?.title)}</Text>
                                <Flex>
                                    <Box
                                        className="event-type"
                                        bg={schedule?.scheduleType === 'Block' ? 'var(--main-color)' : '#4cbfbf'}
                                    >
                                        {schedule?.scheduleType}
                                    </Box>
                                    <Box className="event-studio">{schedule?.studio?.name}</Box>
                                </Flex>
                            </Flex>

                            <Flex justifyContent="space-between">
                                <Text className="event-date">
                                    {getDate({ schedule })}, {getRangeTime({ schedule })}
                                </Text>
                                <Flex
                                    className="more-detail__container"
                                    onClick={() => onChangeState({ isExpandDetail: !state?.isExpandDetail })}
                                >
                                    <Text className="more-detail__text">{isExpandDetail ? 'hide' : 'more'}</Text>
                                    <Box transform={isExpandDetail ? 'rotate(180deg)' : 0}>
                                        <ArrowDownIcon color="var(--main-color)" />
                                    </Box>
                                </Flex>
                            </Flex>

                            <Box className={`more-detail-box__container ${isExpandDetail && 'expand-active'}`}>
                                <Flex alignItems="center">
                                    <Text className="event-items">Account</Text>
                                    <Flex className="items-container">
                                        {schedule?.accountLivestreams?.map((item, idx) => (
                                            <Text className="event-item">
                                                {item?.name} <span>({item?.ecommerce?.name}) </span>
                                                {idx !== schedule?.accountLivestreams?.length - 1 && ','}
                                            </Text>
                                        ))}
                                    </Flex>
                                </Flex>

                                <Flex alignItems="center">
                                    <Text className="event-items">Operator</Text>
                                    <Flex className="items-container">
                                        {schedule?.operators?.map((item, idx) => (
                                            <Text className="event-item">
                                                {item?.name}
                                                {idx !== schedule?.accountLivestreams?.length - 1 && ','}
                                            </Text>
                                        ))}
                                    </Flex>
                                </Flex>

                                <Flex alignItems="center">
                                    <Text className="event-items">Host</Text>
                                    <Flex className="items-container">
                                        {schedule?.hosts?.map((item, idx) => (
                                            <Text className="event-item">
                                                {item?.name}
                                                {idx !== schedule?.accountLivestreams?.length - 1 && ','}
                                            </Text>
                                        ))}
                                    </Flex>
                                </Flex>
                            </Box>
                        </Box>
                    </Box>
                )}

                {/** ------------------------------- ADDITIONAL INFORMATION -------------------------- */}
                {pageType === 'additional-info' && (
                    <>
                        {additionalLive?.isLoading && <AdditionalInformationSkeleton />}
                        {!additionalLive?.isLoading && (
                            <Box className="detail-live__container">
                                <Box className="additional-live__container">
                                    <Flex justifyContent="space-between" mb="24px">
                                        <Text className="label-section">Additional Live Information:</Text>
                                        <Text
                                            className="label-section see-other"
                                            onClick={() => onChangeState({ pageType: 'revenue' })}
                                        >
                                            Lihat Revenue
                                        </Text>
                                    </Flex>

                                    <Box className="additional-live">
                                        <Text className="label-additional">- Product Highlight</Text>
                                        {isEmpty(productHighlights) && (
                                            <Text className="empty-text">Belum ada product highlight</Text>
                                        )}
                                        {productHighlights?.map((item) => (
                                            <>
                                                <Text className="text-item">• {item?.title}</Text>
                                                <Box className="rich-text__box">{parseRichText(item?.description)}</Box>
                                            </>
                                        ))}
                                    </Box>

                                    <Box className="additional-live">
                                        <Text className="label-additional">- Coin</Text>
                                        {isEmpty(coins) && <Text className="empty-text">Belum ada koin</Text>}

                                        {coins?.map((item) => (
                                            <>
                                                <Text className="text-item">• {item?.title}</Text>
                                                <Box className="rich-text__box">{parseRichText(item?.description)}</Box>
                                            </>
                                        ))}
                                    </Box>

                                    <Box className="additional-live">
                                        <Text className="label-additional">- Promotion</Text>
                                        {isEmpty(promotions) && <Text className="empty-text">Belum ada promotion</Text>}

                                        {promotions?.map((item) => (
                                            <>
                                                <Text className="text-item">• {item?.title}</Text>
                                                <Box className="rich-text__box">{parseRichText(item?.description)}</Box>
                                            </>
                                        ))}
                                    </Box>

                                    <Box className="additional-live">
                                        <Text className="label-additional">- Voucher</Text>
                                        {isEmpty(vouchers) && <Text className="empty-text">Belum ada voucher</Text>}
                                        {vouchers?.map((item) => (
                                            <>
                                                <Text className="text-item">• {item?.title}</Text>
                                                <Box className="rich-text__box">{parseRichText(item?.description)}</Box>
                                            </>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </>
                )}

                {/** ------------------------------- REVENUE INFORMATION -------------------------- */}
                {pageType === 'revenue' && (
                    <Box className="detail-live__container">
                        <Box className="revenue-container">
                            <Flex justifyContent="space-between">
                                <Text className="label-section">
                                    Live Revenue:
                                    {!isEmpty(revenue?.data) && (
                                        <Box
                                            ml="4px"
                                            onClick={() =>
                                                navigate(`/schedule-submission/${selectedLiveEvent?.id}?title=Edit`)
                                            }
                                        >
                                            <EditIcon color="var(--main-color)" />
                                        </Box>
                                    )}
                                </Text>{' '}
                                <Text
                                    className="label-section see-other"
                                    onClick={() => onChangeState({ pageType: 'additional-info' })}
                                >
                                    Lihat Additional Info
                                </Text>
                            </Flex>
                            {isEmpty(revenue?.data) && <EmptyState text="Revenue belum ada" />}
                            {revenue?.data?.map((item) => (
                                <Box className="revenue-box">
                                    <Text className="revenue-account">
                                        • {item?.accountLivestreams?.name} <span>| </span>
                                        {item?.accountLivestreams?.ecommerce?.name}
                                    </Text>
                                    <Flex className="total-wrapper">
                                        <Box className="total-container">
                                            <Text className="total-text">
                                                <span>{currency}</span> <CountUp end={item?.revenue} duration={4} />
                                            </Text>
                                            <Text className="revenue-label">Penghasilan</Text>
                                        </Box>
                                        <Box className="total-container">
                                            <Text className="total-text">
                                                <span>{currency}</span>
                                                <CountUp end={item?.individualRevenue} duration={4} />
                                            </Text>
                                            <Text className="revenue-label">Penghasilan sendiri</Text>
                                        </Box>
                                        <Box className="total-container">
                                            <Text className="total-text">
                                                <CountUp end={item?.viewer} duration={4} />
                                                orang
                                            </Text>
                                            <Text className="revenue-label">Total Penonton</Text>
                                        </Box>
                                        <Box className="total-container">
                                            <Text className="total-text">{item?.hoursInString || '0 Menit'}</Text>
                                            <Text className="revenue-label">Lama jam aktual</Text>
                                        </Box>
                                        <Box className="total-container">
                                            <Text className="total-text">
                                                {item?.avgViewDurationInString || '0 Menit'}
                                            </Text>
                                            <Text className="revenue-label">Durasi Rata rata</Text>
                                        </Box>
                                        <Box className="total-container">
                                            <Text className="total-text">
                                                <CountUp end={item?.ctrPercentage} duration={4} />%
                                            </Text>
                                            <Text className="revenue-label">CTR</Text>
                                        </Box>
                                    </Flex>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </ScreenTemplateMobile>
    );
};

export default DetailLiveStream;
