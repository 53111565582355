import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { popupHandler } from '@store/list/popup';
import { payloadFormatting } from './utils';

export const fetchAccountHandler = async ({ state, setState, search }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const queries = queryString.parse(search);

    setState({ ...state, isLoading: true });

    const filterStringify = queryString.stringify(
        { ...queries, limit: 10, page: state?.page },
        { skipEmptyString: true }
    );
    try {
        const fetchedAccounts = await Axios({
            method: 'GET',
            url: `/account-livestream/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { accountLivestreams, totalData, totalPage } = fetchedAccounts?.data?.data;
        // Transform the accountLivestreams array
        const transformedAccounts = accountLivestreams.map((account) => ({
            id: account?.id,
            name: account?.name,
            ecommerce: account?.ecommerce,
            brands: account?.brands,
            accountType: account?.accountType,
            parentAccount: account?.parentAccount,
        }));

        setState({
            ...state,
            accounts: transformedAccounts,
            isLoading: false,
            totalData,
            totalPage,
            dataCount: transformedAccounts.length,
            isCreatePage: false,
            isEditPage: false,
        });
    } catch (error) {
        console.log(error);
        setState({ ...state, accounts: [], isLoading: false });
    }
};

const fetchBrandsHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/brand/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { brands } = data?.data;

        brands = brands?.map((item) => {
            return { label: item.name, value: item?.id, id: item?.id };
        });

        return brands || [];
    } catch (error) {
        return [];
    }
};

const fetchMarketPlaceHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/ecommerce/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { ecommerces } = data?.data;

        ecommerces = ecommerces?.map((item) => {
            return { label: item.name, value: item?.id, id: item?.id };
        });

        return ecommerces || [];
    } catch (error) {
        return [];
    }
};

const fetchParentAccountHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `account-livestream/list?accountTypeId=1&ecommerce.id=1`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const parentAccounts =
            data?.data?.accountLivestreams?.map((item) => {
                return { label: item.name, value: item?.id, id: item?.id };
            }) || [];

        return parentAccounts;
    } catch (error) {
        return [];
    }
};

export const fetchAssetHandler = async ({ setAsset }) => {
    try {
        const brands = await fetchBrandsHandler();
        const marketPlaces = await fetchMarketPlaceHandler();
        const parentAccounts = await fetchParentAccountHandler();

        setAsset({ brands, marketPlaces, parentAccounts });
    } catch (error) {
        // console.log(error);
    }
};

export const removeAccountHandler = async ({ selectedAccount, refetchAccountHandler }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        await Axios({
            method: 'DELETE',
            url: `/account-livestream/${selectedAccount?.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await refetchAccountHandler();

        return {
            title: 'Berhasil menghapus data',
        };
    } catch (error) {
        console.log(error);
        /* eslint-disable */

        throw 'Gagal menghapus data!';
    }
};

export const onSubmitAccount = async ({
    state,
    dispatch,
    setState,
    refetchAccountHandler,
    isEdit,
    selectedAccount,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const payloads = payloadFormatting({ state });

    setState({ ...state, isLoading: true });

    const method = isEdit ? 'PATCH' : 'POST';
    const url = isEdit ? `/account-livestream/${selectedAccount?.id}` : `/account-livestream`;

    try {
        await Axios({
            method,
            url,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });

        await refetchAccountHandler();

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Berhasil menambahkan akun',
                popUpDescription: '',
            })
        );
    } catch (error) {
        console.log(error);
        const message = error?.response?.data?.errors[0]?.message;
        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Failed',
                popUpDescription: message || `Gagal ${isEdit ? 'mengubah' : 'menambahkan'} akun`,
            })
        );
    } finally {
        setState({ ...state, isLoading: false });
    }
};
