import { Text, Flex, Box, Tooltip, Input, Button } from '@chakra-ui/react';
import { EditIcon, RemoveIcon } from '@assets/icons';
import moment from 'moment';

import { useContext } from 'react';
import { TargetScheduleContext } from '@pages/targetSchedule';
import { formatCurrency } from '@helpers/util';

const ActionComponent = ({ values }) => {
    const {
        onEditColumnHandler,
        onShowingRemovePopUp,
        isOpenEditRowTarget,
        selectedTarget,
        onShowingUpdateRowPopUp,
        onChangeState,
    } = useContext(TargetScheduleContext);

    return (
        <Flex className="action-container">
            {isOpenEditRowTarget && selectedTarget?.id === values?.id ? (
                <>
                    <Button
                        className="cancel-button__edit"
                        onClick={() => onChangeState({ isOpenEditRowTarget: false, selectedTarget: null })}
                    >
                        Cancel
                    </Button>
                    <Button className="save-button__edit" onClick={() => onShowingUpdateRowPopUp()}>
                        Save
                    </Button>
                </>
            ) : (
                <>
                    <Box
                        onClick={() => {
                            onEditColumnHandler({ selectedTarget: values });
                        }}
                        className="box-icon"
                    >
                        <EditIcon />
                    </Box>
                    <Box onClick={() => onShowingRemovePopUp({ selectedTarget: values })} className="remove-icon">
                        <RemoveIcon />
                    </Box>
                </>
            )}
        </Flex>
    );
};

const EditableColumn = ({ values, value, type }) => {
    const { selectedTarget, isOpenEditRowTarget, onChangeState } = useContext(TargetScheduleContext);
    const onChangeSelectedTarget = (e) =>
        onChangeState({ selectedTarget: { ...selectedTarget, [type]: e.target.value } });

    return (
        <>
            {selectedTarget?.id === values?.id && isOpenEditRowTarget ? (
                <Input value={selectedTarget[type]} className="editable-input" onChange={onChangeSelectedTarget} />
            ) : (
                <Text className="column-text">{type === 'revenue' ? formatCurrency(value) : value}</Text>
            )}
        </>
    );
};

export const tableList = [
    {
        name: 'Host',
        value: 'host',
        width: 200,
        dataManipulationType: 'search',
        dataManipulationValue: 'host.name',

        component: ({ value }) => <Text className="column-text">{value?.name}</Text>,
    },

    {
        name: 'Brand',
        width: 180,
        value: 'brand',
        component: ({ value }) => <Text className="column-text">{value?.name}</Text>,
    },
    {
        name: 'Target Sales',
        width: 180,
        value: 'revenue',
        dataManipulationType: 'sort',
        dataManipulationValue: 'revenue',
        component: ({ value, values }) => <EditableColumn value={value} values={values} type="revenue" />,
    },
    {
        name: 'Target Jam',
        width: 160,
        value: 'hours',
        dataManipulationType: 'sort',
        dataManipulationValue: 'hours',
        component: ({ value, values }) => <EditableColumn value={value} values={values} type="hours" />,
    },
    {
        name: 'Year Month',
        width: 320,
        value: 'appliedAt',
        component: ({ value }) => (
            <Tooltip label={value}>
                <Text className="column-text ">{moment(value).format('MMMM YYYY')}</Text>
            </Tooltip>
        ),
    },

    {
        name: '-',
        width: 100,
        component: ({ values }) => <ActionComponent values={values} />,
    },
];
