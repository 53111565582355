import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { popupHandler } from '@store/list/popup';
import { payloadFormatting } from './utils';

export const onSubmitUser = async ({ state, dispatch, setState, refetchUserHandler, isEdit, selectedUser }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const payloads = payloadFormatting({ state, isEdit });

    setState({ ...state, isLoading: true });
    dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: `Sedang ${isEdit ? 'mengupdate' : 'membuat'} user....`,
        })
    );
    const method = isEdit ? 'PATCH' : 'POST';
    const url = isEdit ? `/user/${selectedUser?.id}` : `/user`;

    try {
        await Axios({
            method,
            url,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });

        await refetchUserHandler();

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: `Berhasil ${isEdit ? 'memperbarui' : 'menambahkan'} user`,
                popUpDescription: '',
            })
        );
    } catch (error) {
        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Failed',
                popUpDescription: `Gagal ${isEdit ? 'mengubah' : 'menambahkan'} user`,
            })
        );
    } finally {
        setState({ ...state, isLoading: false });
    }
};

export const removeUserHandler = async ({ selectedUser, refetchUserHandler }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        await Axios({
            method: 'DELETE',
            url: `/user/${selectedUser?.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await refetchUserHandler();

        return {
            title: 'Berhasil menghapus data user',
        };
    } catch (error) {
        /* eslint-disable */

        throw 'Gagal menghapus data!';
    }
};

export const fetchUserManagementHandler = async ({ state, setState, search }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const queries = queryString.parse(search);

    setState({ ...state, isLoading: true });

    const filterStringify = queryString.stringify(
        { ...queries, limit: 10, page: state?.page },
        { skipEmptyString: true }
    );
    try {
        const usersFetched = await Axios({
            method: 'GET',
            url: `/user/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { users, totalData, totalPage } = usersFetched?.data?.data;
        setState({
            ...state,
            users: users,
            isLoading: false,
            totalData,
            totalPage,
            dataCount: users?.length,
            isCreatePage: false,
            isEditPage: false,
        });
    } catch (error) {
        console.log(error);
        setState({ ...state, users: [], isLoading: false });
    }
};

export const fetchAssetHandler = async ({ setAsset }) => {
    try {
        const brands = await fetchBrandsHandler();
        const roles = await fetchRoleHandler();

        setAsset({ brands, roles });
    } catch (error) {
        console.log(error);
    }
};
export const fetchBrandsHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const result = await Axios({
            method: 'GET',
            url: `/brand/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        let { brands } = result?.data?.data;

        const data = brands?.map((item) => {
            return { label: item.name, value: item?.name, id: item?.id };
        });

        return data || [];
    } catch (error) {
        return [];
    }
};

export const fetchRoleHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        let result = await Axios({
            method: 'GET',
            url: `/role/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { roles } = result?.data?.data;

        const role = roles?.map((item) => {
            return { label: item.name, value: item?.name, id: item?.id };
        });

        return role || [];
    } catch (error) {
        return [];
    }
};

export const fetchFilterOptionsHandler = async ({ setFilterOptions }) => {
    const brands = await fetchBrandsHandler();
    const role = await fetchRoleHandler();

    setFilterOptions([
        {
            label: 'Jenis Kelamin',
            type: 'dropdown',
            filterName: 'gender',
            options: [
                {
                    value: 'Laki-Laki',
                    name: 'Laki-Laki',
                },
                {
                    value: 'Perempuan',
                    name: 'Perempuan',
                },
            ],
        },
        {
            label: 'Brand',
            filterName: 'brand.name',
            type: 'multipleSelect',

            options: [...brands],
        },
        {
            label: 'Role',
            filterName: 'role.name',
            type: 'multipleSelect',

            options: [...role],
        },
    ]);
};
