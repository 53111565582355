import { useState } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { routeList } from '@routers';
import { IconLogout } from '@assets/icons';

import SideMenuItem from './components/sideMenuItem';
import { getRoutes, onLogout } from './functions';
import { LiveLogo } from './icons';
import { ExpandLeftIcon } from '@assets/icons';
import './styles.scss';

const SideMenu = ({ onHideSideMenu, isHideSideMenu }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [page, setPage] = useState('/');

    useEffect(() => {
        const { pathname } = location;
        setPage(pathname);
    }, [location]);

    return (
        <Box className={`side-menu__wrapper`}>
            <Box className="side-menu__container">
                {/** ----------------------------- HEAD -------------------------- */}

                <Box>
                    <Box className="section-head__side-menu">
                        <Box className="logo-image">
                            <LiveLogo />
                        </Box>

                        <Box
                            className="icon-expand__container"
                            transform={`rotate(${isHideSideMenu ? '180deg' : '0'})`}
                            onClick={onHideSideMenu}
                        >
                            <ExpandLeftIcon />
                        </Box>
                    </Box>

                    {/** ----------------------------- LIST ROUTE -------------------------- */}

                    <Box className="section-body__side-menu">
                        {getRoutes({ routeList }).map(({ icon, path, name, children }) => (
                            <SideMenuItem
                                icon={icon}
                                path={path}
                                page={page}
                                name={name}
                                children={children}
                                navigate={navigate}
                            />
                        ))}
                    </Box>
                </Box>

                {/** ----------------------------- LOGOUT -------------------------- */}

                <Flex alignItems="center" cursor="pointer" p="34px" onClick={() => onLogout({ dispatch, navigate })}>
                    <IconLogout />
                    <Text fontSize="14px">Logout</Text>
                </Flex>
            </Box>
        </Box>
    );
};

export default SideMenu;
