import moment from 'moment';
import { convertToRaw } from 'draft-js';
import { sortBy } from 'lodash';

export const payloadFormatting = ({ state }) => {
    const { title, description, startedAt, endedAt, accounts } = state;
    const accountLivestream = accounts?.map((item) => item.id);
    const dateTimeStarted = `${moment(startedAt).format('YYYY-MM-DD')}T00:00:00`;
    const dateTimeEnded = `${moment(endedAt).format('YYYY-MM-DD')}T23:59:59`;

    return {
        title,
        description: JSON.stringify(convertToRaw(description.getCurrentContent())),
        startedAt: `${moment(dateTimeStarted).utc().toISOString()}`,
        endedAt: `${moment(dateTimeEnded).utc().toISOString()}`,
        accountLivestream,
    };
};

export const getBrandColor = ({ name }) => {
    const obj = {
        Wonderly: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/wonderly.png',
            name: 'Wonderly',
        },
        Biodef: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/biodef.png',
            name: 'Biodef',
        },
        Instaperfect: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/instaperfect.png',
            name: 'Instaperfect',
        },
        OMG: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/omg-icon.png',
            name: 'OMG',
        },
        Putri: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/putri.png',
            name: 'Putri',
        },
        'Make Over': {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/make over.png',
            name: 'Make Over',
        },
        Labore: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/labore.png',
            name: 'Labore',
        },
        Beyondly: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/beyondly.png',
            name: 'Beyondly',
        },
        Crystallure: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/crystallure.png',
            name: 'Crystallure',
        },
        Tavi: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/tavi.png',
            name: 'Tavi',
        },
        Emina: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/emina.png',
            name: 'Emina',
        },
        Kahf: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/kahf.png',
            name: 'Kahf',
        },
        Wardah: {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/wardah.png',
            name: 'Wardah',
        },
        'Earth Love Life': {
            link: 'https://bivi.pti-cosmetics.com/logo_brand/earth love life.png',
            name: 'Earth Love Live',
        },
    };
    return obj[name];
};
export const sortByBrandHandler = (arr) => {
    return sortBy(arr, ({ name }) => name);
};

export const formattingSelectedStateHandler = ({ arr, type }) => {
    const obj = {
        accounts: arr?.map(({ name, id, ecommerce }) => {
            return {
                label: name,
                value: name,
                id,
            };
        }),
        marketPlaces: arr?.map(({ ecommerce }) => {
            return {
                label: ecommerce?.name,
                value: ecommerce?.name,
            };
        })[0],
    };
    return obj[type];
};
