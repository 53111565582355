import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { popupHandler } from '@store/list/popup';
import { payloadFormatting } from './utils';

export const fetchProductHighlightHandler = async ({ state, setState, search }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const queries = queryString.parse(search);

    setState({ ...state, isLoading: true });

    const filterStringify = queryString.stringify(
        { ...queries, limit: 10, page: state?.page },
        { skipEmptyString: true }
    );
    try {
        const products = await Axios({
            method: 'GET',
            url: `/product-highlight/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { productHighlights, totalData, totalPage } = products?.data?.data;

        setState({
            ...state,
            products: productHighlights,
            isLoading: false,
            totalData,
            totalPage,
            dataCount: productHighlights?.length,
            isEditPage: false,
            isCreatePage: false,
        });
    } catch (error) {
        console.log(error);
        setState({ ...state, products: [], isLoading: false });
    }
};

const fetchBrandsHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/brand/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { brands } = data?.data;

        brands = brands?.map((item) => {
            return { label: item.name, value: item?.name, id: item?.id };
        });

        return brands || [];
    } catch (error) {
        return [];
    }
};

const fetchMarketPlaceHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/ecommerce/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { ecommerces } = data?.data;

        ecommerces = ecommerces?.map((item) => {
            return { label: item.name, value: item?.name, id: item?.id };
        });

        return ecommerces || [];
    } catch (error) {
        return [];
    }
};

const fetchAccountHandler = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/product-highlight/account-livestream/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let { accountLivestreams } = data?.data;

        accountLivestreams = accountLivestreams?.map((item) => {
            return { label: item.name, value: item?.name, id: item?.id, ecommerce: item?.ecommerce?.name };
        });

        return accountLivestreams || [];
    } catch (error) {
        return [];
    }
};

export const fetchAssetHandler = async ({ setAsset }) => {
    try {
        const brands = await fetchBrandsHandler();
        const marketPlace = await fetchMarketPlaceHandler();
        const accounts = await fetchAccountHandler();

        setAsset({ brands, marketPlace, accounts });
    } catch (error) {
        console.log(error);
    }
};

export const removeProductHighlightHandler = async ({ selectedProduct, refetchProductHandler }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        await Axios({
            method: 'DELETE',
            url: `/product-highlight/${selectedProduct?.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await refetchProductHandler();

        return {
            title: 'Berhasil menghapus data',
        };
    } catch (error) {
        console.log(error);
        /* eslint-disable */

        throw 'Gagal menghapus data!';
    }
    h;
};

export const onSubmitProduct = async ({ state, dispatch, setState, refetchProductHandler, isEdit, selectedProduct }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const payloads = payloadFormatting({ state });

    setState({ ...state, isLoading: true });
    dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: 'Sedang mengirim data...',
            popUpDescription: '',
        })
    );
    const method = isEdit ? 'PATCH' : 'POST';
    const url = isEdit ? `/product-highlight/${selectedProduct?.id}` : `/product-highlight`;

    try {
        await Axios({
            method,
            url,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payloads,
        });

        await refetchProductHandler();

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Berhasil menambahkan produk',
                popUpDescription: '',
            })
        );
    } catch (error) {
        console.log(error);
        const message = error?.response?.data?.errors[0]?.message;
        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Failed',
                popUpDescription: message || `Gagal ${isEdit ? 'mengubah' : 'menambahkan'} produk`,
            })
        );
    } finally {
        setState({ ...state, isLoading: false });
    }
};
